import classnames from 'classnames';
import React, { FC } from 'react';
import { ThreeDots } from 'react-loader-spinner';
import styles from './index.sass';

interface TProps extends React.ComponentProps<typeof ThreeDots> {
  className?: string;
  color?: string;
  width?: number;
  height?: number;
  size?: number;
}

export const Loader: FC<React.PropsWithChildren<TProps>> = ({
  className,
  color = '#42526E',
  size = 50,
  width,
  height,
}) => (
  <div
    data-testid="loading_spinner"
    className={classnames(className, styles.loader)}
    style={{
      width,
      height,
    }}
  >
    <ThreeDots color={color} height={size} width={size} />
  </div>
);
